import { format } from "date-fns";

export function toDateTimeLocalFormat(value?: string) {
  if (value === undefined) {
    return;
  }
  return format(new Date(value), "yyyy-MM-dd'T'HH:mm:ss");
}

export function toDateTimeBrFormat(value?: string) {
  if (value === undefined) {
    return;
  }
  var date = new Date(value);
  var userTimezoneOffset = date.getTimezoneOffset() * 60000;
  date = new Date(date.getTime() + userTimezoneOffset);
  return date.toLocaleDateString('pt-BR');
}
