import { IntlEnum, Title } from '@common/components';
import { GridViewProvider } from '@common/context/GridView/useGridView';
import GridBatch from '../components/GridBatch/GridBatch';


export default function ListReimbursements() {
    return (
        <GridViewProvider>
            <Title>
                <IntlEnum name="reimbursement" value="reimbursement"/>
            </Title>
            <GridBatch />
        </GridViewProvider>
    )
}
