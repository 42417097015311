import { CursorPaginated } from "@types";
import { PaginationParams } from "types/PaginationParams";
import { ReimbursementAPI } from "types/ReimbursementAPI";

export function convertReimbursementResponseCursorPaginated<T>(response: ReimbursementAPI<T>, pageInfo: PaginationParams): CursorPaginated<T> { 
  return {
      pageInfo: {
        hasPreviousPage: response.hasPreviousPage,
        hasNextPage: response.hasNextPage,
        currentPage: Math.ceil((pageInfo.offset + 1) / pageInfo.limit),
        lastPage: Math.ceil(response.totalCount / pageInfo.limit),
        perPage: response.pageCount,
        fromCursor: pageInfo.offset + 1,
        toCursor: pageInfo.offset + response.pageCount,
        total: response.totalCount
      },
      edges: response.data
    }
  }
  