import { useMemo } from "react";
import { Column } from "react-table";
import { 
  IntlEnum, 
  IntlMoney,
  MultiSelectColumnFilter,
} from "@common/components";
import { Batch } from "@reimbursement/batch/contracts/Batch";
import { getValueEnumObject } from "@shared/helpers";
import { EnumReimbursementStatus } from "@reimbursement/batch/enums/EnumReimbursementStatus";
import { ReimbursementStatus } from "@reimbursement/batch/types/ReimbursementStatus";
import useReimbursementStatus from "@reimbursement/batch/hooks/useReimbursementStatus";
import BatchAcceptanceModal from "../BatchAcceptanceModal";
import { ExportReimbursement } from "../ExportReimbursement";

type IndustryFilter = {
  key: 'string';
  value: 'string';
}

export default function GridColumns(industries?: IndustryFilter[]) {

  type ReimbursementRowData = {
    row: {
      original: Batch
    }
  }
  
  const statuses = useReimbursementStatus().map((s: {name: string, code: ReimbursementStatus}) => {
    return s.code;
  });

  const columns: Column<Batch>[] = useMemo(() => [
    {
      Header: <IntlEnum name="reimbursement_grid" value="id" />,
      accessor: "id",
    },
    {
      Header: <IntlEnum name="reimbursement_grid" value="external_id" />,
      accessor: "externalId",
    },
    {
      Header: <IntlEnum name="reimbursement_grid" value="industry_name" />,
      accessor: "industry_name",
      isVisible: false
    },
    {
      Header: <IntlEnum name="reimbursement_grid" value="industry_code" />,
      accessor: "industry_code",
      options: industries || [],
      Filter: MultiSelectColumnFilter,
      isVisible: false,
      multiple: true,
    },

    {
      Header: <IntlEnum name="reimbursement_grid" value="status" />,
      accessor: "status",
      isVisible: false,
      Filter: MultiSelectColumnFilter,
      options: statuses,
      Cell: ({ value }) => getValueEnumObject<ReimbursementStatus>(EnumReimbursementStatus, value),
    },
    {
      Header: <IntlEnum name="reimbursement_grid" value="division" />,
      accessor: "division",
      isVisible: false,
    },
    {
      Header: <IntlEnum name="reimbursement_grid" value="visited" />,
      accessor: "visited",
      isVisible: false,
      Cell: ({ value }) => { return (value === true ? 'Sim' : 'Não')}
    },
    {
      Header: <IntlEnum name="reimbursement_grid" value="rating" />,
      accessor: "rating",
      isVisible: false,
    },
    {
      Header: <IntlEnum name="reimbursement_grid" value="predicted_value" />,
      accessor: "predicted_value",
      isVisible: false,
      Cell: ({ value }) => <IntlMoney value={value} />
    },
    {
      Header: <IntlEnum name="reimbursement_grid" value="deduction" />,
      accessor: "deduction",
      isVisible: false,
      Cell: ({ value }) => <IntlMoney value={value} />
    },
    {
      Header: <IntlEnum name="reimbursement_grid" value="period" />,
      accessor: "period",
      isVisible: false,
    },
    {
      Header: <IntlEnum name="reimbursement_grid" value="user" />,
      accessor: "user",
      isVisible: false,
    },
    {
      Header: "",
      id: "accept",
      width: '10px',
      Cell: ({ row }: ReimbursementRowData) => (
        BatchAcceptanceModal(row.original)
      ),
    },
    {
      Header: "",
      id: "export",
      width: '10px',
      Cell: ({ row }: ReimbursementRowData) => (
        ExportReimbursement({
          recordId: row.original.id,
          status: row.original.status
        })
      ),
    },
  ],[]);

  return columns;
}
