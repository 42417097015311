import { IntlEnum, Button } from "@common/components";
import { useGridView } from "@common/context/GridView/useGridView";
import ClearIcon from '@mui/icons-material/Clear';

export function ClearFilterButton(props: any) {
  const { setClearFilters, setGridFilters } = useGridView();

  return (
    <Button 
      type="button"
      size="medium"
      className="float-right"
      onClick={() => {
        setClearFilters(true)
         setGridFilters([])
        }}
    >
      <ClearIcon className="mr-2" />
      <IntlEnum name="filter" value="clear_filters"/>
    </Button>
  )
}
