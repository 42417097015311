import React from "react";
import { ButtonExport, EmailDialog } from "@shared/components";
import { IntlEnum } from "@common/components";
import { useJwtUser } from "@common/hooks";

export type ExportReimbursementParams = {
  recordId: string;
  status: string;
}

export function ExportReimbursement (params: ExportReimbursementParams) {
  const [open, setOpen] = React.useState(false)
  const text = <IntlEnum name="reimbursement_grid" value="export_data"/>;
  const { user } = useJwtUser();
   
  const handleClickOpen = () => {
    setOpen(true);
  };

  return (
    <>
      <ButtonExport 
        data-testid={'export-modal-button'} 
        disabled={params.status === 'EXCLUIDO'} 
        onClick={handleClickOpen} 
      />
      <EmailDialog
        open={open}
        onHandleClick={setOpen}
        serviceType={"RE"}
        modalText={text}
        userEmail={user?.email}
        recordId={params.recordId}
      />
      
    </>
  )
}