import { Button, DatePicker } from "@common/components";
import { useExportButton, useGridView } from "@common/context/GridView/useGridView";
import { Grid } from "@mui/material";
import { SubmitHandler, useForm } from "react-hook-form";
import { format } from 'date-fns';
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect } from 'react';

export function setDateFilter(date: Date, isBefore: boolean = false): string {
  isBefore ? date.setHours(23, 59, 59) : date.setHours(0, 0, 0, 0);
  return format(date, "yyy-MM-dd HH:mm:ss");
}

export type FilterTopProps = {
  begin_date: Date | null;
  end_date: Date | null;
};

const schema = yup.object({
  begin_date: yup
    .date()
    .nullable()
    .required('Campo obrigatório')
    .test(
      "start-test-lesser-than-end",
      "Data inicial deve ser menor que a final",
      (value, context) => {
        const startDate = new Date(value || context.parent.begin_date);
        const endDate = new Date(context.parent.end_date || new Date(context.parent.begin_date.getTime() + 86400000));
        return endDate > startDate;
      }
    )
    .test(
      "start-format-test",
      "Formato de data inválido",
      (value, context) => {
        const startDate = new Date(context.parent.begin_date).toLocaleString().split(',')[0];
        return /^(0?[1-9]|[12][0-9]|3[01])[\/](0?[1-9]|1[012])[\/\-]\d{4}$/g.test(startDate);
      }
    )
    .typeError('Formato de data inválido'),
  end_date: yup
    .date()
    .nullable()
    .required('Campo obrigatório')
    .test(
      "end-format-test",
      "Formato de data inválido",
      (value, context) => {
        const startDate = new Date(context.parent.begin_date).toLocaleString().split(',')[0];
        return /^(0?[1-9]|[12][0-9]|3[01])[\/](0?[1-9]|1[012])[\/\-]\d{4}$/g.test(startDate);
      }
    )
    .test(
      "end-bigger-than-start",
      "Data inicial deve ser menor que a final",
      (value, context) => {
        const startDate = new Date(context.parent.begin_date);
        const endDate = new Date(context.parent.end_date);
        return endDate > startDate;
      }
    )
    .typeError('Formato de data inválido')
});

export function FilterTopReimbursement(props: FilterTopProps) {

  const { setDisableExportButton } = useExportButton();
  const { setGridFilters, gridFilters, items } = useGridView();

  useEffect(() => {
    if (items) {
      if (items?.pageInfo?.total <= 0) {
        setDisableExportButton(true)
      }
    }
  }, [items])
  

  const onSubmit: SubmitHandler<FilterTopProps> = (data: FilterTopProps) => {
    let filters = gridFilters
  
    filters = filters.filter((filter) => {
      return filter.id !== 'begin_date' && filter.id !== 'end_date'
    })

    filters.push(
      { "id": "begin_date", "value": setDateFilter(data.begin_date || new Date()) },
      { "id": "end_date", "value": setDateFilter(data.end_date || new Date(), true) },
    )
    
    setGridFilters(filters);
    setDisableExportButton(false)
  }

  const { handleSubmit, control, formState: { errors, isValid } } = useForm<FilterTopProps>(
    {
      defaultValues: props,
      mode: 'onChange',
      reValidateMode: 'onChange',
      resolver: yupResolver(schema)
    }
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid
        container
        direction="row"
        justifyContent="center"
        columnSpacing={1}
        sx={{ marginBottom: 4 }}
      >
        <Grid item xs={2.0} lg={2.0}>
          <DatePicker
            label="Data Inicial"
            data-testid="begin_date"
            control={control}
            controllerName="begin_date"
            errorMessage={errors.begin_date?.message}
          />
        </Grid>
        <Grid item xs={2.0} lg={2.0}>
          <DatePicker
            label="Data Final"
            control={control}
            data-testid="end_date"
            controllerName="end_date"
            errorMessage={errors.end_date?.message}
          />
        </Grid>
        <Grid 
          item 
          xs={1} 
          container 
        >
          <Grid item>
            <Button disabled={!isValid} size="medium" type="submit">BUSCAR </Button>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
}
