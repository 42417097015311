import { IRoute } from "@src/routes";
import ListReimbursements from "./pages/ListReimbursementsPage";

const routes: IRoute[] = [
    {
        menu: { label: "Pedidos" },
        path: "/reimbursement/batches",
        key: "ORDER_LIST",
        exact: true,
        component: ListReimbursements,
    }
];

export default routes;
