import { gql } from "graphql-request";

export interface ExportReimbursementBatchDetailsInput {
  input: {
    email: string;
    ids: string[] | number[] | undefined;
  }
}

export async function SendExportReimbursementBatchDetails(options: ExportReimbursementBatchDetailsInput, request: any){

  const { exportReimbursementBatchDetails } = await request(
    gql`
    mutation exportReimbursementBatchDetails($input:ExportReimbursementBatchDetailsInput!) {
        exportReimbursementBatchDetails(input:$input)
      }`, options
  )
  

  return exportReimbursementBatchDetails
}
