import { gql } from "graphql-request";

export interface AcceptReimbursementInput {
  input: {
    id: string;
  }
}

export async function useAcceptReimbursements(options: AcceptReimbursementInput, request: any){

  const { acceptReimbursement } = await request(
    gql`
    mutation acceptReimbursement($input:AcceptReimbursementInput!) {
        acceptReimbursement(input:$input)
      }`, options
  )

  return acceptReimbursement
}
