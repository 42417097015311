import { useQuery } from "react-query";
import { gql } from "graphql-request";
import { useReimbursementAPI } from "@common/hooks/useReimbursementAPI";

export function useIndustries(){

  const { request } = useReimbursementAPI();

  return useQuery("industries: ", async () => {
      const { allIndustries } = await request(
        gql`
        {
          allIndustries(
            filters: {}
          ) {
            edges {
              cursor
              node{
                id
                code
                name
              }
            }
          }
        }
        `
      );
      return await allIndustries;
  }, {
    refetchOnWindowFocus: false
  });
}

