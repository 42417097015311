import { Route, Switch } from 'react-router-dom';
import LoginPage from '@common/pages/LoginPage';
import caRoutes from '@ca/routes';
import weRoutes from '@we/routes';
import reimbursementRoutes from '@reimbursement/routes';

const ROUTES: IRoute[] = [
  {
    path: '/',
    key: 'ROOT',
    exact: true,
    component: LoginPage,
  },
  ...caRoutes,
  ...weRoutes,
  ...reimbursementRoutes,
];

export interface IRouteMenu {
  label: string;
  icon?: JSX.Element;
}

export interface IRoute {
  path: string;
  key: string;
  exact?: boolean;
  component: any;
  routes?: IRoute[];
  menu?: IRouteMenu;
}

export default ROUTES;

function RouteWithSubRoutes(route: IRoute) {
  return (
    <Route
      path={route.path}
      exact={route.exact}
      render={(props: any) => (
        <route.component {...props} routes={route.routes} />
      )}
    />
  );
}

export function RenderRoutes({ routes }: { routes: IRoute[] }) {
  return (
    <Switch>
      {routes.map((route, i) => {
        return <RouteWithSubRoutes {...route} />;
      })}
      <Route component={() => <h1>Página não encontrada</h1>} />
    </Switch>
  );
}
