import { GraphQLClient } from "graphql-request";
import { useAuth } from "react-oauth2-pkce";

const endpoint = `${
  process?.env?.REACT_APP_REIMBURSEMENT_URL ||
  window?.environment?.REACT_APP_REIMBURSEMENT_URL
}/graphql`;

export function useReimbursementAPI() {
  const { authService } = useAuth();
  const jwt = authService.getAuthTokens().access_token;
  const graphQLClient = new GraphQLClient(endpoint, {
    headers: {
      authorization: `Bearer ${jwt}`,
    },
  });

  return {
    graphQLClient,
    request: graphQLClient.request.bind(graphQLClient),
  };
}
